import $ from "jquery";
import("swiper/scss");

(async () => {
  const { default: Swiper } = await import("swiper");
  $(".blog--type-2 .js-slider").each(function () {
    new Swiper(this, {
      slidesPerView: 1.1,
      spaceBetween: 24,
    });
  });
})();
